//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import { put } from 'redux-saga/effects';

import SagaStateHelper                  from '@helper/SagaStateHelper';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';

const stagingPassword = 'rHef3y6ZaG6oonppLwzH';

function* authenticate() {
    const passwordFromState = yield SagaStateHelper.selectFromState('stagingAuthentication', 'password');
    const isAuthenticated   = stagingPassword === passwordFromState;

    yield put(StagingAuthenticationActions.authenticateSucceeded({
        isAuthenticated,
    }));
}

function* reset() {
    yield put(StagingAuthenticationActions.reset());
}

export default {
    authenticate,
    reset,
};
// TODO-Skeleton end
