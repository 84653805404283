//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO-Skeleton start
import React from 'react';

import { Route }  from 'react-router';
import { Switch } from 'react-router';

import Footer            from '@connected/Footer';
import Header            from '@connected/Header';
import OverlayManager    from '@connected/OverlayManager';
import Routes            from '@constants/Routes';
import CheckoutScreen    from '@screens/CheckoutScreen';
import DeactivatedScreen from '@screens/DeactivatedScreen';
import Home              from '@screens/Home';
import NotFound          from '@screens/NotFound';

const propTypes = {};

const MainRouter = () => {
    return (
        <>
            <OverlayManager />
            <Header />
            <Switch>
                <Route
                    exact
                    path={Routes.home}
                    component={Home}
                />
                <Route
                    exact
                    path={Routes.checkout}
                    component={CheckoutScreen}
                />
                <Route
                    exact
                    path={Routes.deactivated}
                    component={DeactivatedScreen}
                />
                <Route component={NotFound} />
            </Switch>
            <Footer />
        </>
    );
};

MainRouter.propTypes = propTypes;

export default MainRouter;
