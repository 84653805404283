//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import 'core-js';
import '@language/Language';
import 'moment/locale/de';

import { ConnectedRouter } from 'connected-react-router';
import moment              from 'moment';
import { createRoot }      from 'react-dom/client';
import ReactGA             from 'react-ga4';
import { Provider }        from 'react-redux';
import { PersistGate }     from 'redux-persist/integration/react';

import LoadingOverlay            from '@connected/LoadingOverlay';
import MainRouter                from '@connected/MainRouter';
import OrderPriceCalculator      from '@connected/OrderPriceCalculator';
import StagingLoginOverlay       from '@connected/StagingLoginOverlay';
import TimeSlotExpirationWatcher from '@connected/TimeSlotExpirationWatcher';
import TimeSlotFetcher           from '@connected/TimeSlotFetcher';
import WindowVisibilityWatcher   from '@connected/WindowVisibilityWatcher';
import Environment               from '@helper/Environment';
import ImageStorage              from '@helper/ImageStorage';
import Language                  from '@helper/Language';
import CacheInvalidator          from '@stateless/atomic/CacheInvalidator';
import NotificationHandler       from '@stateless/composed/NotificationHandler';
import NotificationPosition      from '@stateless/composed/NotificationHandler/NotificationPosition';
import { history }               from '@store';
import createStore               from '@store';

import * as serviceWorker from './serviceWorker';
import styles             from './styles.module.scss';

import '@external/sentry';
import '@store/miscellaneous/immutability-helper-extensions';

ImageStorage.initializeStore(); // TODO-Skeleton

moment.locale(Language.getDefaultLanguageShort()); // TODO-Skeleton

if (Environment.isProduction()) {
    ReactGA.initialize('G-EQHZCBGPEQ');
} else if (Environment.isStaging()) {
    ReactGA.initialize('G-KQR4XWK4VN');
}

const { store, persistor } = createStore();
const container            = document.getElementById('root');
const root                 = createRoot(container);

root.render((
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <CacheInvalidator />
            {/*
                If you don't want to automatically clear the browsers cache and update the
                application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
                to ask the user if he wants to update.
            */}
            <StagingLoginOverlay>
                <LoadingOverlay />
                <WindowVisibilityWatcher />
                <TimeSlotFetcher />
                <TimeSlotExpirationWatcher />
                <OrderPriceCalculator />
                <ConnectedRouter history={history}>
                    <div className={styles.appContent}>
                        <NotificationHandler position={NotificationPosition.center} />
                        <MainRouter />
                    </div>
                </ConnectedRouter>
            </StagingLoginOverlay>
        </PersistGate>
    </Provider>
));

serviceWorker.register();
