//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';
import { memo }     from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';

import PropTypes                      from '@components/PropTypes';
import TimeSlotItem                   from '@connected/TimeSlotItem';
import TimeSlotSelectorLoadingOverlay from '@connected/TimeSlotSelectorLoadingOverlay';
import DateTime                       from '@helper/DateTime';
import TimeSlotHelper                 from '@helper/TimeSlotHelper';
import Translator                     from '@helper/Translator';
import Button                         from '@stateless/atomic/Button';
import ButtonColor                    from '@stateless/atomic/Button/ButtonColor';
import ButtonType                     from '@stateless/atomic/Button/ButtonType';
import Carousel                       from '@stateless/atomic/Carousel';
import ArrowSize                      from '@stateless/atomic/Carousel/ArrowSize';
import Headline                       from '@stateless/atomic/Headline';
import HeadlineType                   from '@stateless/atomic/Headline/HeadlineType';

import styles from './styles.module.scss';

const propTypes = {
    groupedTimeSlots: PropTypes.object,
};

const TimeSlotSelector = ({
    groupedTimeSlots = null,
}) => {
    const [expanded, setExpanded] = useState(false);
    const translator              = new Translator('components.timeSlotSelector.');
    const carouselOptions         = {
        width:       'calc(100% - 80px)',
        autoHeight:  true,
        perMove:     4,
        perPage:     4,
        gap:         5,
        pagination:  false,
        drag:        false,
        breakpoints: {
            420: {
                perPage: 2,
                perMove: 2,
            },
            550: {
                perPage: 3,
                perMove: 3,
            },
        },
    };

    function renderTimeSlots(timeSlotIris) {
        return _.map(timeSlotIris, (timeSlotIri) => {
            return (
                <TimeSlotItem
                    key={timeSlotIri}
                    timeSlotIri={timeSlotIri}
                />
            );
        });
    }

    function renderTimeSlotRangeItems() {
        return _.map(groupedTimeSlots, (timeSlotGroup, groupDate) => {
            const dayStringKey = TimeSlotHelper.getDayShortKeyByDate(groupDate);
            const dayString    = I18n.t(dayStringKey);
            const dateString   = TimeSlotHelper.getDateString(groupDate);
            const isToday      = DateTime.isToday(groupDate);

            function getDateString() {
                return isToday
                    ? translator.translate('today')
                    : dateString;
            }

            return (
                <div
                    key={groupDate}
                    className={classNames(
                        styles.timeSlotRangeItem,
                        {
                            [styles.today]: isToday,
                        },
                    )}
                >
                    <div className={styles.rangeHeader}>
                        <div>
                            {dayString}
                        </div>
                        <div>
                            {getDateString()}
                        </div>
                    </div>
                    <div className={styles.timeSlotsContainerGroup}>
                        <div
                            className={classNames(
                                styles.timeSlotsContainer,
                                {
                                    [styles.expanded]: expanded,
                                },
                            )}
                        >
                            {renderTimeSlots(timeSlotGroup)}
                        </div>
                    </div>
                </div>
            );
        });
    }

    function toggleExpandClicked() {
        setExpanded(!expanded);
    }

    function renderExpandButton() {
        const text = expanded
            ? translator.translate('collapse')
            : translator.translate('expand');

        return (
            <Button
                type={ButtonType.text}
                text={text}
                textColor={ButtonColor.orange}
                onClick={toggleExpandClicked}
            />
        );
    }

    return (
        <div className={styles.timeSlotSelector}>
            <Headline
                type={HeadlineType.headline2}
                title={translator.translate('headline')}
            />
            <Carousel
                className={styles.arrowsTop}
                arrowSize={ArrowSize.small}
                options={carouselOptions}
            >
                {renderTimeSlotRangeItems()}
            </Carousel>
            <div className={styles.expandButtons}>
                {renderExpandButton()}
            </div>
            <TimeSlotSelectorLoadingOverlay />
        </div>
    );
};

TimeSlotSelector.propTypes = propTypes;

export default memo(TimeSlotSelector);
