//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes     from '@components/PropTypes';
import Translator    from '@helper/Translator';
import Button        from '@stateless/atomic/Button';
import ButtonColor   from '@stateless/atomic/Button/ButtonColor';
import ButtonType    from '@stateless/atomic/Button/ButtonType';
import IconType      from '@stateless/atomic/Icon/IconType';
import TextInputSize from '@stateless/atomic/InputWrapper/TextInputSize';
import TextInput     from '@stateless/atomic/TextInput';

import styles from './styles.module.scss';

const propTypes = {
    customerComment:          PropTypes.string,
    onCustomerCommentChanged: PropTypes.func,
};

const MAX_LENGTH = 160;

const NoteInput = ({
    customerComment          = '',
    onCustomerCommentChanged = _.noop,
}) => {
    const translator                      = new Translator('components.noteInput.');
    const [open, setOpen]                 = useState(false);
    const [previousNote, setPreviousNote] = useState(null);

    function openNoteInputClicked() {
        setOpen(true);
        setPreviousNote(customerComment);
    }

    function handleNoteChange(event) {
        const note = event.target.value;
        onCustomerCommentChanged(note);
    }

    function onSaveNoteClick() {
        setOpen(false);
    }

    function onCancelNoteClick() {
        setOpen(false);
        onCustomerCommentChanged(previousNote);
        setPreviousNote(null);
    }

    function composeNoteLabelText() {
        const noteTranslation = translator.translate('note');

        if (
            !open
        ) {
            if (!customerComment) {
                return noteTranslation;
            }

            return translator.translate('edit');
        }

        return `${noteTranslation} ${customerComment.length}/${MAX_LENGTH}`;
    }

    function renderNote() {
        if (!customerComment) {
            return null;
        }

        return (
            <div
                className={classNames(
                    styles.noteLabel,
                    {
                        [styles.show]: !open,
                    },
                )}
            >
                {customerComment}
            </div>
        );
    }

    function renderNoteInput() {
        return (
            <div
                className={classNames(
                    styles.noteTextInput,
                    {
                        [styles.open]: open,
                    },
                )}
            >
                <TextInput
                    multiline={true}
                    maxLength={MAX_LENGTH}
                    value={customerComment}
                    onKeyDown={handleNoteChange}
                    onChange={handleNoteChange}
                    size={TextInputSize.big}
                />
                <div className={styles.noteInputButtons}>
                    <Button
                        type={ButtonType.text}
                        text={translator.translate('cancel')}
                        onClick={onCancelNoteClick}
                    />
                    <Button
                        type={ButtonType.text}
                        textColor={ButtonColor.orange}
                        text={translator.translate('add')}
                        onClick={onSaveNoteClick}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.noteInput}>
            <div
                className={styles.noteTextLabel}
                onClick={openNoteInputClicked}
            >
                <Button
                    type={ButtonType.text}
                    text={composeNoteLabelText()}
                    textColor={ButtonColor.orange}
                    iconRight={IconType.note}
                />
            </div>
            {renderNote()}
            {renderNoteInput()}
        </div>
    );
};

NoteInput.propTypes = propTypes;

export default NoteInput;
