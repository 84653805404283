//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes            from '@components/PropTypes';
import ProductType          from '@constants/ProductType';
import DateHelper           from '@helper/Date';
import Translator           from '@helper/Translator';
import ReservationCountDown from '@stateless/atomic/ReservationCountDown';

import styles from './styles.module.scss';

const propTypes = {
    children:         PropTypes.node,
    currentOrder:     PropTypes.object,
    expanded:         PropTypes.bool,
    selectedTimeSlot: PropTypes.object,
    show:             PropTypes.bool,
};

const OrderInfoButton = ({
    children         = null,
    show             = true,
    currentOrder     = null,
    expanded         = true,
    selectedTimeSlot = null,
}) => {
    const translator = new Translator('components.orderInfoButton.');

    function renderNumberOfFreePizzas() {
        const availableSlots   = selectedTimeSlot?.availableSlots;
        const numberOfProducts = _.sumBy(currentOrder?.orderProducts, (orderProduct) => {
            if (orderProduct.type === ProductType.pizza) {
                return orderProduct.quantity;
            }

            return 0;
        });

        if (!availableSlots) {
            return null;
        }

        const numberOfFreePizzas = availableSlots - numberOfProducts;
        let text                 = translator.translate(
            'availableSlots',
            {
                availableSlots: numberOfFreePizzas,
            },
        );

        if (numberOfFreePizzas === 0) {
            text = translator.translate('noAvailableSlots');
        } else if (numberOfFreePizzas < 0) {
            text = translator.translate('tooManyPizzas');
        }

        return (
            <p>
                {text}
            </p>
        );
    }

    function renderCountDown() {
        const reservedTillDateString = selectedTimeSlot?.reservedTill;

        if (!reservedTillDateString) {
            return null;
        }

        const reservedTill = DateHelper.dateStringToTimestamp(reservedTillDateString);

        return (
            <p>
                {translator.translate('timeSlotPrefix')}
                <ReservationCountDown
                    toTimeStamp={reservedTill}
                    onlyTime={true}
                />
                {translator.translate('timeSlotSuffix')}
            </p>
        );
    }

    if (!show) {
        return null;
    }

    return (
        <div className={styles.orderInfoCheckoutButton}>
            <div
                className={
                    classNames(
                        styles.orderInfoContainer,
                        {
                            [styles.expanded]: expanded,
                        },
                    )
                }
            >
                {renderNumberOfFreePizzas()}
                {renderCountDown()}
            </div>
            {children}
        </div>
    );
};

OrderInfoButton.propTypes = propTypes;

export default OrderInfoButton;
