//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _      from 'lodash';
import moment from 'moment';

// TODO-Skeleton start
export default class DateHelper {
    static getCurrentWeekDays() {
        const currentWeekDays = [];
        const currentDate     = new Date();
        const weekDay         = this.setToBeginOfWeek(currentDate);

        for (let dayOfWeek = 1; dayOfWeek <= 7; dayOfWeek++) {
            const dayOfTheYear = this.getDayOfTheYear(weekDay);

            currentWeekDays.push({
                dayOfYear: dayOfTheYear,
                year:      currentDate.getFullYear(),
            });

            weekDay.setDate(weekDay.getDate() + 1);
        }

        return currentWeekDays;
    }

    static getDaysInMonth(month, year, onlyWorkingDays = false, fillToFullWeek = false) {
        const days = [];
        const date = new Date(year, month, 1);

        if (fillToFullWeek) {
            const momentDate = moment(date);
            const maxWeekDay = _.min([
                momentDate.isoWeekday(), onlyWorkingDays
                    ? 6
                    : 7,
            ]);

            for (
                let daysInFirstCalendarWeekPreviousMonth = 1;
                daysInFirstCalendarWeekPreviousMonth < maxWeekDay;
                daysInFirstCalendarWeekPreviousMonth++
            ) {
                days.push(momentDate.subtract(1, 'days').toDate());
            }
        }

        for (let dayInMonth = 1; dayInMonth <= 31; dayInMonth++) {
            if (date.getMonth() !== month) {
                break;
            }

            const day = date.getDay();

            if (
                !onlyWorkingDays ||
                (
                    onlyWorkingDays &&
                    day >= 1 &&
                    day <= 6
                )
            ) {
                days.push(new Date(date));
            }

            date.setDate(date.getDate() + 1);
        }

        if (fillToFullWeek) {
            const momentDate     = moment(date);
            const currentWeekDay = momentDate.isoWeekday();

            if (currentWeekDay > 1) {
                const lastDayOfWeek = (
                    onlyWorkingDays ?
                        6 :
                        7
                );

                if (currentWeekDay < lastDayOfWeek) {
                    for (
                        let daysInLastCalendarWeek = currentWeekDay;
                        daysInLastCalendarWeek <= lastDayOfWeek;
                        daysInLastCalendarWeek++
                    ) {
                        days.push(momentDate.toDate());

                        momentDate.add(1, 'day');
                    }
                }
            }
        }

        return days;
    }

    static isWorkingDay(date, holidays = []) {
        const day          = date.getDay();
        const holidayMatch = _.find(holidays, (holiday) => {
            if (moment(holiday.date).isSame(date, 'day')) {
                return holiday;
            }

            return false;
        });

        return (
            !holidayMatch &&
            day !== 0
        );
    }

    static getNextWorkingDay(date, holidays = []) {
        const momentDate = moment(date);
        const nextDate   = momentDate.add(1, 'day').toDate();

        if (this.isWorkingDay(nextDate, holidays)) {
            return nextDate;
        }

        return this.getNextWorkingDay(nextDate, holidays);
    }

    static getPreviousWorkingDay(date, holidays = []) {
        const momentDate = moment(date);
        const nextDate   = momentDate.subtract(1, 'day').toDate();

        if (this.isWorkingDay(nextDate, holidays)) {
            return nextDate;
        }

        return this.getPreviousWorkingDay(nextDate, holidays);
    }

    static isSameDay(dateLeftSide, dateRightSide) {
        return dateLeftSide.getFullYear() === dateRightSide.getFullYear() &&
            dateLeftSide.getMonth() === dateRightSide.getMonth() &&
            dateLeftSide.getDate() === dateRightSide.getDate();
    }

    static getCurrentMonthName() {
        const currentDate = new Date();

        return this.getMonthName(currentDate.getMonth());
    }

    static getMonthName(utcMonth) {
        const monthNames = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ];

        return monthNames[utcMonth];
    }

    static getCurrentMonth() {
        const currentDate = new Date();

        return this.getMonth(currentDate);
    }

    static getMonth(date) {
        return date.getMonth() + 1;
    }

    static getCurrentYear() {
        const currentDate = new Date();

        return currentDate.getFullYear();
    }

    static isHoliday(date, holidays) {
        const holidayMatch = _.find(holidays, (holiday) => {
            const holidayDate = new Date(holiday.date);

            if (
                holidayDate.getDay() === date.getDay() &&
                holidayDate.getMonth() === date.getMonth() &&
                holidayDate.getFullYear() === date.getFullYear()
            ) {
                return holiday;
            }

            return false;
        });

        return !!holidayMatch;
    }

    static setToBeginOfWeek(date) {
        const day = (
            date.getDay() ||
            7
        );

        if (day !== 1) {
            const previousDay = day - 1;

            date.setHours(-24 * previousDay);
        }

        return date;
    }

    static getWeekOfTheYear(date) {
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

        utcDate.setUTCDate(utcDate.getUTCDate() + 4
            - (
                utcDate.getUTCDay() ||
                7
            ));

        const yearStart = new Date(Date.UTC(
            utcDate.getUTCFullYear(),
            0,
            1,
        ));
        const weekNo    = Math.ceil((
            (
                (
                    utcDate - yearStart
                ) / 86400000
            ) + 1
        ) / 7);

        return [utcDate.getUTCFullYear(), weekNo];
    }

    static getDayOfTheYear(date) {
        const dayOfYear = moment(date).dayOfYear();

        return dayOfYear;
    }

    /**
     * @param dayOfYear First day of the year is 1
     * @param year
     * @returns {Date}
     */
    static getDateByDayOfYear(dayOfYear, year) {
        const date = new Date(year, 0);

        return new Date(date.setDate(dayOfYear));
    }

    static isLeapYear(date) {
        const year = date.getFullYear();

        if (
            (
                year & 3
            ) !== 0
        ) {
            return false;
        }

        return (
            (
                year % 100
            ) !== 0
            ||
            (
                year % 400
            ) === 0
        );
    }

    static dateStringToTimestamp(dateString) {
        const date = new Date(dateString);

        return (
            date.getTime() / 1000
        );
    }
}
// TODO-Skeleton end
