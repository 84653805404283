//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put } from 'redux-saga/effects';

import { AdditionalRequestsActions } from '@slices/additionalRequests';
import { AllergensActions }          from '@slices/allergens';
import { AppSettingsActions }        from '@slices/appSettings';
import { ConfigurationActions }      from '@slices/configuration';
import { DeliveryExceptionsActions } from '@slices/deliveryExceptions';
import { OrdersActions }             from '@slices/orders';
import { ProductAdditionsActions }   from '@slices/productAdditions';
import { ProductsActions }           from '@slices/products';
import { RestaurantsActions }        from '@slices/restaurants';
import { TimeSlotsActions }          from '@slices/timeSlots';

function* preLoadData() {
    yield put(ConfigurationActions.fetchConfiguration());
    yield put(ProductsActions.fetchProducts());
    yield put(AppSettingsActions.fetchSettingsInInterval());
    yield put(AllergensActions.fetchAllergens());
    yield put(AdditionalRequestsActions.fetchAdditionalRequests());
    yield put(ProductAdditionsActions.fetchProductAdditionsRecursively());
    yield put(TimeSlotsActions.resetTimeSlots());
    yield put(RestaurantsActions.fetchRestaurants());
    yield put(OrdersActions.checkOrderSource());
    yield put(DeliveryExceptionsActions.fetchDeliveryExceptions());
}

export default {
    preLoadData,
};
