//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push } from 'connected-react-router';
import _        from 'lodash';
import { put }  from 'redux-saga/effects';

import Routes                     from '@constants/Routes';
import SagaStateHelper            from '@helper/SagaStateHelper';
import { AlertBoxActions }        from '@slices/alertBox';
import { BrowserActions }         from '@slices/browser';
import { selectActiveAppSetting } from '@store/selectors/appSettings';
import selectCurrentRoute         from '@store/selectors/navigation';

function* locationChange() {
    const appSettings = yield SagaStateHelper.selectBySelector(selectActiveAppSetting);
    const route       = yield SagaStateHelper.selectBySelector(selectCurrentRoute);

    if (
        !appSettings?.enablePizzaBooking &&
        route !== Routes.deactivated
    ) {
        yield put(push(Routes.deactivated));
    } else if (
        appSettings?.enablePizzaBooking &&
        route === Routes.deactivated
    ) {
        yield put(push(Routes.home));
    }

    yield put(AlertBoxActions.alertBoxLocationChange());
    yield put(BrowserActions.scrollToTop());
}

function* redirect(action) {
    const payload = _.get(action, 'payload', {});

    yield put(push(payload.route));
}

export default {
    locationChange,
    redirect,
};
